import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import FaceIcon from '@material-ui/icons/Face'
import { useLogin } from './contexts/LoginContext'
import { Avatar, IconButton, Tooltip, Link, Badge } from '@material-ui/core'
import { Group } from '@material-ui/icons'
import { LogoutComponent } from './LoginComponent'
import { SettingsModal } from './ToolBarModal'
import dark_vs from './dark_vs.json'
import { useUserConfig } from './contexts/UserConfigContext'
import SettingsIcon from '@material-ui/icons/Settings'
import { MyClassesDialog } from './MyClassesDialog/MyClassesDialog'
import UserProgressFetchDialog from './UserProgressFetchDialog'
import { loginHref } from './LandingDialog'
import { toggleGroupsDialogOpenState } from './contexts/GroupsContext'
import { useContests } from './contexts/ContestContext'
import { useNotifications } from './contexts/NotificationsContext'
import { NotificationsDialog } from './NotificationsDialog'
import BarChartIcon from '@material-ui/icons/BarChart'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { FIRIA_GREEN } from './FiriaGreenButton'

const useStyles = makeStyles(theme => ({
  rounded: {
    color: '#fff',
    backgroundColor: green[500],
  },
  small: {
    width: 28,
    height: 28,
  },
  clickable: {
    cursor: 'pointer',
  },
  end: {
    justifyContent: 'flex-end',
  },
  iconButton: {
    height: 40,
    width: 40,
  },
  badge: {
    bottom: '2px',
    right: '4px',
    width: 12,
    height: 12,
    fontSize: 12,
    color: '#44b700',
    padding: '0 0px',
    backgroundColor: '#44b700',
  },
  barContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  topContainer: {
    paddingTop: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 5,
  },
  contestBadge: {
    backgroundColor: FIRIA_GREEN,
    color: FIRIA_GREEN,
  },
}))

export default function AccountToolbar() {
  const classes = useStyles()
  const avatarRef = React.useRef(null)
  const [showLoginScreen, setShowLoginScreen] = React.useState(null)
  const [showSettings, setShowSettings] = React.useState(false)
  const [loginState] = useLogin()
  const [userConfigState] = useUserConfig()
  const { contestsStateInterface, activePackHasActiveContest } = useContests()
  const { notificationsStateInterface, unreadNotifications } = useNotifications()

  return (
    <>
      <div className={classes.barContainer} style={{ justifyContent: 'space-between', height: '100%' }}>
        <div className={classes.topContainer}>
          <Tooltip title='Select Class' placement='right' arrow>
            <IconButton onClick={toggleGroupsDialogOpenState} className={classes.iconButton}>
              <Group style={{ fontSize: 30, color: userConfigState.theme === 'dark' ? dark_vs.colors['menu.foreground'] : null }} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Progress and Contests' placement='right' arrow>

            <IconButton onClick={contestsStateInterface.openDialog} className={classes.iconButton}>
              <Badge invisible={!activePackHasActiveContest} badgeContent={1} showZero={false} variant='dot' classes={{ badge: classes.contestBadge }}>
                <BarChartIcon style={{ fontSize: 30, color: userConfigState.theme === 'dark' ? dark_vs.colors['menu.foreground'] : null }} />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title='Notifications' placement='right' arrow>
            <IconButton onClick={notificationsStateInterface.openDialog} className={classes.iconButton}>
              <Badge badgeContent={unreadNotifications} color='error' max={9}>
                <NotificationsIcon style={{ fontSize: 30, color: userConfigState.theme === 'dark' ? dark_vs.colors['menu.foreground'] : null }} />
              </Badge>
            </IconButton>
          </Tooltip>
        </div>
        <div className={`${classes.barContainer} ${classes.end}`} style={{ marginBottom: 6, gap: 10 }}>
          <Tooltip title='Preferences' placement='right' arrow>
            <IconButton onClick={() => setShowSettings(true)} className={classes.iconButton}>
              <SettingsIcon style={{ color: userConfigState.theme === 'dark' ? dark_vs.colors['menu.foreground'] : null }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={!loginState?.user ? 'Sign In' : 'Account: ' + loginState?.user?.email } placement='right' arrow>
            {!loginState?.user ?
              <Link href={loginHref}>
                <Avatar
                  ref={avatarRef}
                  variant='rounded'
                  className={`${classes.rounded} ${classes.small} ${classes.clickable}`}
                  alt={loginState?.user?.displayName ?? null}
                >
                  <i className='material-icons'>login</i>
                </Avatar>
              </Link> : <Avatar
                ref={avatarRef}
                variant='rounded'
                className={`${classes.rounded} ${classes.small} ${classes.clickable}`}
                alt={loginState?.user?.displayName ?? null}
                onClick={() => setShowLoginScreen('logout')}
              >
                <FaceIcon />
              </Avatar>
            }
          </Tooltip>
        </div>
      </div>
      <LogoutComponent
        // open={true}
        open={showLoginScreen === 'logout'}
        onClose={() => setShowLoginScreen(null)}
      />
      <SettingsModal
        open={showSettings}
        onClose={() => setShowSettings(false)}
      />
      <MyClassesDialog />
      <NotificationsDialog />
      <UserProgressFetchDialog logOut={() => setShowLoginScreen('logout')}/>
    </>
  )
}
