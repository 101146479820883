import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { usbDebugBackend } from './Players'

const useStyles = makeStyles((theme) => {
  const darkMode = theme?.palette?.type === 'dark' ?? false
  return ({
    snack: {
    },
    refText: {
      color: darkMode ? 'blue' : 'white',
    },
  })
})

export default function HwVerMessage(props) {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const [target, setTarget] = React.useState('')

  // this callback could be moved to a context if necessary
  const upgradeMsg = (device, displayName) => {
    setTarget(displayName)
    setOpen(true)
  }

  usbDebugBackend.setUpgradeDeviceCb(upgradeMsg)

  const handleClose = (ev) => {
    setOpen(false)
  }

  const action = (
    <IconButton
      size='small'
      aria-label='close'
      color='inherit'
      onClick={handleClose}
    >
      <CloseIcon fontSize='small' />
    </IconButton>
  )

  const msg = (
    <>
      <p>Your <b>{target}</b>'s firmware version is out of date.</p>
      <p style={{textDecoration: 'none'}}>Go to <a className={classes.refText} href='https://upgrade.firialabs.com'>https://upgrade.firialabs.com</a> to upgrade!</p>
    </>
  )

  return (
    <Snackbar
      className={classes.snack}
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      autoHideDuration={null}
      open={open}
      message={msg}
      action={action}
    />
  )
}