import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { PowerOff, Power } from '@material-ui/icons'

import { useCodePanel } from './contexts/CodePanelContext'
import { useScene } from './contexts/SceneContext'
import { TargetNames, Targets } from './Players'
import { TargetSelectionModal } from './ToolBarModal'
import { useDebugger, DebuggerActions } from './contexts/DebuggerContext'

const useStyles = makeStyles(() => ({
  codingFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    backgroundColor: '#007ac6',
    color: 'white',
  },
  bottomBar: {
    height: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 13,
  },
  bottomBarText: {
    fontWeight: 'lighter',
    fontSize: 12,
    userSelect: 'none',
  },
  greenConnected: {
    backgroundColor: '#3ba380',
    justifyContent: 'flex-start',
    padding: '0px 4px 0px 0px',
  },
  redDisconnected: {
    backgroundColor: '#ff0033',
    justifyContent: 'flex-start',
    padding: '0px 4px 0px 0px',
    color: 'white',
  },
  blueLineCol: {
    backgroundColor: '#007ACC',
    justifyContent: 'flex-end',
    padding: '0px 4px 0px 4px',
  },
  footerIcons: {
    fontSize: 20,
  },
}))


export default function CodingFooter() {
  const [codePanelState] = useCodePanel()
  const [debuggerState, debuggerDispatch] = useDebugger()
  const [linePositionText, setLinePositionText] = React.useState('Ln 1, Col 1')
  const [sceneState] = useScene()
  const classes = useStyles()
  const isProperTargetConnected = (
    (sceneState.target === Targets.UNCONNECTED) ||
    sceneState?.deviceIsTarget
  )

  React.useEffect(() => {
    if (!!codePanelState.editorInstance) {
      codePanelState.editorInstance.onDidChangeCursorSelection(function (ev) {
        if (ev.selection.startLineNumber === ev.selection.endLineNumber && ev.selection.startColumn === ev.selection.endColumn) {
          setLinePositionText('Ln ' + ev.selection.positionLineNumber + ', Col ' + ev.selection.positionColumn)
        } else {
          let selectionLength = codePanelState.editorInstance.getModel().getValueInRange(ev.selection).length
          setLinePositionText('Ln ' + ev.selection.positionLineNumber + ', Col ' + ev.selection.positionColumn + ' (' + selectionLength + ' selected)')
        }
      })
    }
  }, [codePanelState.editorInstance])

  const showTargetSelect = (doShow) => {
    debuggerDispatch({ type: DebuggerActions.DEBUG_SHOW_TARGET_SELECT, shouldShow: doShow })
  }

  return (
    sceneState.target === null || (
      <>
        <div className={classes.codingFooter}>
          <div
            className={`${classes.bottomBar} ${isProperTargetConnected ? classes.greenConnected : classes.redDisconnected}`}
            onClick={() => showTargetSelect(true)}
            style={{ cursor: 'pointer' }}
          >
            {sceneState.device ? <Power className={classes.footerIcons} /> : <PowerOff className={classes.footerIcons} />}
            <div className={classes.bottomBarText}>
              {TargetNames[sceneState.target]}
              {isProperTargetConnected ?
                ' Connected' :
                sceneState.device && sceneState.device !== Targets.UNCONNECTED && sceneState.device !== sceneState.target ?
                  ` Required - Incorrect Device (${TargetNames[sceneState.device]})!` :
                  ' Disconnected - Click to Connect!'
              }
            </div>
          </div>
          <div className={`${classes.bottomBar} ${classes.blueLineCol}`}>
            <div className={classes.bottomBarText}>
              {linePositionText}
            </div>
          </div>
        </div>
        <TargetSelectionModal
          open={debuggerState.showTargetSelectDialog}
          onClose={() => showTargetSelect(false)}
        />
      </>
    ))
}