// Coding Panel
import React from 'react'
import { useCodePanel } from './contexts/CodePanelContext'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { Tooltip } from '@material-ui/core'
import './App.css'
import { useDebugger, DebuggerActions } from './contexts/DebuggerContext'
import CheckBoxOutlineBlankSharpIcon from '@material-ui/icons/CheckBoxOutlineBlankSharp'
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined'
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined'
import { Divider } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
// import PauseIcon from '@material-ui/icons/Pause'
import StopIcon from '@material-ui/icons/Stop'
import BugReportOutlinedIcon from '@material-ui/icons/BugReportOutlined'
import { useUserConfig } from './contexts/UserConfigContext'
import { TargetStates, TargetModes, DebugCommands } from './TargetInterface'
import dark_vs from './dark_vs.json'
import { ContinueIcon, StepOverIcon, StepIntoIcon, StepOutIcon } from './assets/SvgIcons'
import { useScene } from './contexts/SceneContext'

const useStyles = makeStyles((theme) => {
  return ({
    iconButton: {
      borderRadius: 5,
      height:24,
      width:24,
      '&:disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.06)',
        color: 'rgb(0, 0, 0)',
        opacity: '.3',
      },
    },
    debugIconButton: {
      borderRadius: 5,
      marginLeft:3,
      height:24,
      width:24,
      '&:disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.06)',
        color: 'rgb(0, 0, 0)',
        opacity: '.3',
      },
    },
    contentRow: {
      height:'100%',
      display:'flex',
      flexDirection:'row',
    },
  })
})

export default function DebugButtons() {
  const [sceneState] = useScene()
  const [codePanelState] = useCodePanel()
  const [debuggerState, debuggerDispatch] = useDebugger()
  const classes = useStyles()
  const [userConfigState] = useUserConfig()

  const sendCommand = (command) => {
    debuggerDispatch({ type: DebuggerActions.DEBUG_USER_COMMAND, debugCommand: command  })
  }

  const runStateIcon = {
    [TargetStates.STOPPED]: (<PlayArrowOutlinedIcon style={{fontSize: 24, color: '#89d185'}}/>),
    [TargetStates.STOPPING]: (<PlayArrowOutlinedIcon style={{fontSize: 24, color: '#89d185'}}/>),
    [TargetStates.LOADING]: (<CircularProgress size={13} style={{ position: 'absolute', padding:0, margin:0, color: userConfigState.theme === 'dark' ? dark_vs.colors['menu.foreground'] : null}}/>),
    [TargetStates.RUNNING]: (<StopIcon style={{fontSize: 22, color: userConfigState.theme === 'dark' ? dark_vs.colors['menu.foreground'] : null}}/>),
    // You must account for ALL possible states to prevent the button from disappearing
    [TargetStates.AWAITING_INPUT]: (<PlayArrowOutlinedIcon style={{fontSize: 24, color: '#89d185'}}/>),
  }

  const TooltipIconButton = (props) => {
    const parseProps = () => {
      const { children, title, ...formattedProps } = props
      return formattedProps
    }

    return (
      <Tooltip title={props.title} placement='bottom' arrow>
        <div>
          <IconButton {...parseProps()}>
            {props.children}
          </IconButton>
        </div>
      </Tooltip>
    )
  }

  return (
    <div style={{height:'100%'}}>
      <div className={classes.contentRow}>
        <div className={classes.contentRow}>
          {debuggerState.mode === TargetModes.DEBUG ?
            <div className={classes.contentRow}>
              <div>
                <Divider orientation={'vertical'}/>
              </div>
              <TooltipIconButton
                title='Continue'
                disabled={debuggerState.targetState !== TargetStates.AWAITING_INPUT}
                onClick={()=>sendCommand(DebugCommands.CONTINUE)}
                className={classes.debugIconButton}
              >
                <ContinueIcon style={{position:'relative', top:3, left:4, fontSize: 19, color: userConfigState.theme === 'dark' ? '#75beff' : null}}/>
              </TooltipIconButton>
              {/*
                <TooltipIconButton
                  title='Pause'
                  onClick={()=>sendCommand(DebugCommands.PAUSE)}
                  className={classes.debugIconButton}
                >
                  <PauseIcon style={{fontSize: 19, color: '#75beff'}}/>
                </TooltipIconButton>
                */}
              <TooltipIconButton
                title='Step Over'
                disabled={debuggerState.targetState !== TargetStates.AWAITING_INPUT}
                onClick={()=>sendCommand(DebugCommands.STEP_OVER)}
                className={classes.debugIconButton}
              >
                <StepOverIcon style={{overflow: 'visible', position:'relative', bottom: 2, right: 2, fontSize: 20, color: userConfigState.theme === 'dark' ? '#75beff' : null}}/>
              </TooltipIconButton>
              <TooltipIconButton
                title='Step In'
                disabled={debuggerState.targetState !== TargetStates.AWAITING_INPUT}
                onClick={()=>sendCommand(DebugCommands.STEP_INTO)}
                className={classes.debugIconButton}
              >
                <StepIntoIcon style={{position:'relative', bottom: 2, right: 2, fontSize: 20, color: userConfigState.theme === 'dark' ? '#75beff' : null}}/>
              </TooltipIconButton>
              <TooltipIconButton
                title='Step Out'
                disabled={debuggerState.targetState !== TargetStates.AWAITING_INPUT}
                onClick={()=>sendCommand(DebugCommands.STEP_OUT)}
                className={classes.debugIconButton}
              >
                <StepOutIcon style={{overflow: 'visible', position:'relative', bottom: 2, right: 2, fontSize: 20, color: userConfigState.theme === 'dark' ? '#75beff' : null}}/>
              </TooltipIconButton>
              <TooltipIconButton
                title='Restart Program'
                onClick={()=>sendCommand(DebugCommands.RESTART)}
                className={classes.debugIconButton}
              >
                <RefreshOutlinedIcon style={{fontSize: 19, color: '#89d185', transform: 'scaleX(-1)'}}/>
              </TooltipIconButton>
              <TooltipIconButton
                title='Stop Debugging'
                onClick={()=>sendCommand(DebugCommands.STOP)}
                className={classes.debugIconButton}
                style={{marginRight:3}}
              >
                <CheckBoxOutlineBlankSharpIcon style={{fontSize: 16, color: '#f48771'}}/>
              </TooltipIconButton>
            </div>:
            null}
        </div>
        {debuggerState.mode !== TargetModes.DEBUG ?
          <div className={classes.contentRow}>
            <TooltipIconButton
              title='Debug Program'
              onClick={() => debuggerDispatch({ type: DebuggerActions.DEBUG })}
              disabled={
                (debuggerState.targetState !== TargetStates.STOPPED &&
                debuggerState.targetState !== TargetStates.AWAITING_INPUT) ||
                !codePanelState.tabs.focused ||
                !sceneState.ready ||
                !sceneState.deviceIsTarget ||
                sceneState.backend?.deviceData?.[sceneState.device]?.numBreakpoints === 0  // No debug capability
              }
              className={classes.iconButton}
              style={{marginRight: 3}}
            >
              <BugReportOutlinedIcon style={{fontSize: 20, color: userConfigState.theme === 'dark' ? dark_vs.colors['menu.foreground'] : null}}/>
            </TooltipIconButton>
            <TooltipIconButton
              title={debuggerState.targetState === TargetStates.RUNNING ? 'Stop Running' : 'Run Program'}
              onClick={() => debuggerDispatch({ type:DebuggerActions.RUN })}
              disabled={
                !codePanelState.editorReady ||
                debuggerState.targetState === TargetStates.LOADING ||
                (!codePanelState.tabs.focused && debuggerState.targetState !== TargetStates.RUNNING) ||
                debuggerState.targetState === TargetStates.STOPPING ||
                !sceneState.ready
              }
              className={classes.iconButton}
              style={{marginRight:3}}
            >
              {debuggerState.mode === TargetModes.RUN ? runStateIcon[debuggerState.targetState]:runStateIcon[TargetStates.STOPPED]}
            </TooltipIconButton>
          </div>:
          null}
      </div>
    </div>
  )
}